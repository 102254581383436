<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
import xlsx from "xlsx";
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:1000,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,

				xlsx_list:[]
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.start()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {		
			//生成csv
			make_csv(){
					const _data = [
					// 代表 导出 列标题
					[
					"运单id",
					"上游编号",
					"运单编号",

					"组编号",

					"发货时间",


					"收款人电话",
					"收款人姓名",

					"发货地省份",
					"发货地城市",
					"发货地县区",
					"发货地详细地址",
					"装货联系人",
					"装货联系电话",
					"货物名称",
					"到货地省份",
					"到货地城市",
					"到货地县区",
					"到货地详细地址",
					"到货地联系人",
					"到货地联系电话",
					"货物类型",
					"运输距离",
					"运费",

					// "服务费",
					// "结算给车队长",
					// "结算给司机",
					// "已支付",
					// "已结(车队长)",
					// "已结(司机)",
					// "应付总服务费",
					// "结算完成时间",
					// "运单上报时间",
					// "出金上报时间",
					// "匹配方式",
					// "运单状态",
					// "锁定状态",
					// "支付状态",
					// "开票状态",
					// "结算状态",
					// "部SDK上报状态",
					// "省监管上报状态",
					// "省监管上报节点",
					"结算方式",
					"是否回程",
					"货主电话",
					"货主姓名",
					"货主公司",
					"车牌号",

					"货物重量(吨)",
					// "到货重量(吨)",
					"货物体积(方)",
					// "磅单编号",
					// "已付总服务费",
					// "可开票总金额(元)",
					// "运费分配方式",
					// "计算方式",
					// "已付(含服务费)",
					// "已开票(含服务费)",
					// "已开票(不含服务费)",
					"备注",
					// "发票备注",
					// "公司利润",
					// "保障服务费",
					"货物金额",
					],
				];
				this.xlsx_list.forEach((item) => {
					_data.push(Object.values(item));
				});
				const ws = xlsx.utils.aoa_to_sheet(_data);
				const wb = xlsx.utils.book_new();
				xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
				xlsx.writeFile(wb, "运单列表.xlsx");
				this.xlsx_list = []
			},
			isNumeric(val) {
				return !isNaN(parseFloat(val));
			},
			//读取
			read(){
				var p = this.p
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_deal_list_admin',
						num:this.num,
						p:p,
						...this.rull,
					},
					callback:(data)=>{
						this.p ++
						let symbols = {
							'~':' ',
							';':' ',
							"-":'',
							'↵':''
						}
						if(data.list.length ==0){
							this.p = 1
							this.read()
							return
						}
						//预处理
						for(let item of data.list){
							//货源详情
							var cargo_info=item.cargo_info

							//发货时间
							cargo_info.creat_time_text=this.$my.other.totime(cargo_info.creat_time);

							//装货联系人
							if(cargo_info.case_link_man){
								cargo_info.case_link_man_text=cargo_info.case_link_man;
							}else cargo_info.case_link_man_text="无";

							//装货联系电话
							if(cargo_info.case_link_tel){
								cargo_info.case_link_tel_text=cargo_info.case_link_tel;
							}else cargo_info.case_link_tel_text="无";

							//卸货联系人
							if(cargo_info.aim_link_man){
								cargo_info.aim_link_man_text=cargo_info.aim_link_man;
							}else cargo_info.aim_link_man_text="无";

							//卸货联系电话
							if(cargo_info.aim_link_tel){
								cargo_info.aim_link_tel_text=cargo_info.aim_link_tel;
							}else cargo_info.aim_link_tel_text="无";
							cargo_info.case_other_obj=cargo_info.case_other_obj.replace('\n','')
							/* 装货地其他数据(json) */
							cargo_info.case_other_obj=JSON.parse(cargo_info.case_other);

							//装货时间
							if(!cargo_info.case_other_obj.case_time_start && cargo_info.case_other_obj.case_time_end){//只有止点

								cargo_info.case_other_obj.case_date=this.$my.other.todate(cargo_info.case_other_obj.case_time_end)+"以前";

							}else if(cargo_info.case_other_obj.case_time_start && !cargo_info.case_other_obj.case_time_end){//只有起点

								cargo_info.case_other_obj.case_date=this.$my.other.todate(cargo_info.case_other_obj.case_time_start)+"以后";

							}else if(cargo_info.case_other_obj.case_time_start && cargo_info.case_other_obj.case_time_end){//都有

								cargo_info.case_other_obj.case_date=this.$my.other.todate(cargo_info.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(cargo_info.case_other_obj.case_time_end);

							}else if(!cargo_info.case_other_obj.case_time_start && !cargo_info.case_other_obj.case_time_end){//都没有

								cargo_info.case_other_obj.case_date="未设置";
							}

							//发车时间
							if(!cargo_info.case_other_obj.start_time_1 && cargo_info.case_other_obj.start_time_2){//只有止点

								cargo_info.case_other_obj.start_time=this.$my.other.todate(cargo_info.case_other_obj.start_time_2)+"以前";

							}else if(cargo_info.case_other_obj.start_time_1 && !cargo_info.case_other_obj.start_time_2){//只有起点

								cargo_info.case_other_obj.start_time=this.$my.other.todate(cargo_info.case_other_obj.start_time_1)+"以后";

							}else if(cargo_info.case_other_obj.start_time_1 && cargo_info.case_other_obj.start_time_2){//都有

								cargo_info.case_other_obj.start_time=this.$my.other.todate(cargo_info.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(cargo_info.case_other_obj.start_time_2);

							}else if(!cargo_info.case_other_obj.start_time_1 && !cargo_info.case_other_obj.start_time_2){//都没有
								
								cargo_info.case_other_obj.start_time="未设置";
							}
							// console.log(cargo_info)
							//发车时间是否强制校验
							switch(cargo_info.case_other_obj.start_time_is_check){
								case '1':cargo_info.case_other_obj.start_time_is_check_text="(强制)";break;
								case '2':cargo_info.case_other_obj.start_time_is_check_text="(不强制)";break;
							}
							if(cargo_info.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								cargo_info.case_other_obj.start_time_is_check_text=""
							}
							// for(const symbol in symbols){
							// 	cargo_info.aim_other = cargo_info.aim_other.replace(new RegExp(symbol,'g'),symbols[symbol]);
							// }
							cargo_info.aim_other=cargo_info.aim_other.replace('\n','')
							/* 卸货地其他数据(json) */
							cargo_info.aim_other_obj=JSON.parse(cargo_info.aim_other);

							//卸货时间
							if(!cargo_info.aim_other_obj.aim_time_start && cargo_info.aim_other_obj.aim_time_end){//只有止点

								cargo_info.aim_other_obj.aim_date=this.$my.other.todate(cargo_info.aim_other_obj.aim_time_end)+"以前";

							}else if(cargo_info.aim_other_obj.aim_time_start && !cargo_info.aim_other_obj.aim_time_end){//只有起点

								cargo_info.aim_other_obj.aim_date=this.$my.other.todate(cargo_info.aim_other_obj.aim_time_start)+"以后";

							}else if(cargo_info.aim_other_obj.aim_time_start && cargo_info.aim_other_obj.aim_time_end){//都有

								cargo_info.aim_other_obj.aim_date=this.$my.other.todate(cargo_info.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(cargo_info.aim_other_obj.aim_time_end);

							}else if(!cargo_info.aim_other_obj.aim_time_start && !cargo_info.aim_other_obj.aim_time_end){//都没有
								
								cargo_info.aim_other_obj.aim_date="未设置";
							}
							
							//结算方式
							switch(cargo_info.settlement_type){
								case '1':cargo_info.settlement_type_text="按吨数";break;
								case '2':cargo_info.settlement_type_text="按方数";break;
								case '3':cargo_info.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(cargo_info.is_need_return){
								case '1':cargo_info.is_need_return_text="需要回程";break;
								case '2':cargo_info.is_need_return_text="不需要回程";break;
							}

							//运费
							if(cargo_info.freight_total>0){
								
								cargo_info.freight_total_text=cargo_info.freight_total+" 元";

							}else cargo_info.freight_total_text="未设置";

							//货车类型
							if(cargo_info.need_truck_type){
								cargo_info.need_truck_type_text=cargo_info.need_truck_type+' / '+cargo_info.need_truck_type2
							}else cargo_info.need_truck_type_text="无要求"

							//运输距离
							if(cargo_info.distance>0){
								cargo_info.distance_text=cargo_info.distance+'公里'
							}else item.distance_text="未知"

							this.xlsx_list.push({
								id: item.id,
								out_trade_num: cargo_info.out_trade_num,
								truck_tord_num: item.truck_tord_num,

								group1_num: cargo_info.group1_num,
								cargo_creat_time_text: cargo_info.creat_time_text,



								truck_owner_tel: item.truck_owner_info.tel,
								truck_owner_name: item.truck_owner_info.name,

								// driver_tel: item.driver_tel,
								// driver_name: item.driver_name,
								// id_card_num: item.id_card_num,
								case_prov: cargo_info.case_prov,
								case_city: cargo_info.case_city,
								case_county: cargo_info.case_county,

								case_addr_info: cargo_info.case_addr_info,

								case_link_man: cargo_info.case_link_man,
								case_link_tel: cargo_info.case_link_tel,
								cargo_name: cargo_info.cargo_name,
								aim_prov: cargo_info.aim_prov,
								aim_city: cargo_info.aim_city,
								aim_county: cargo_info.aim_county,
								aim_addr_info: cargo_info.aim_addr_info,
								aim_link_man: cargo_info.aim_link_man,
								aim_link_tel: cargo_info.aim_link_tel,

								cargo_type: cargo_info.cargo_type,

								distance: cargo_info.distance,
								
								freight_total:cargo_info.freight_total,

								// service_charge: item.service_charge,
								// give_truck_owner_total: item.give_truck_owner_total,
								// give_driver_total: item.give_driver_total,
								// payed_total_of_out_sc: item.payed_total_of_out_sc,
								// truck_owner_settled_total: item.truck_owner_settled_total,
								// need_service_total: item.need_service_total,
								// driver_settled_total: item.driver_settled_total,
								// settled_time_text: item.settled_time_text,
								// tord_upld_time_text: item.tord_upld_time_text,
								// outcash_upld_time_text: item.outcash_upld_time_text,

								// match_way_text: item.match_way_text,
								// status_text: item.status_text,
								// lock_status_text: item.lock_status_text,
								// payed_status_text: item.payed_status_text,
								// invoiced_status_text: item.invoiced_status_text,
								// settled_status_text: item.settled_status_text,

								// wlhy_sdk_status_text: item.wlhy_sdk_status_text,
								// upl_status_text: item.upl_status_text,
								// upl_aim: item.upl_aim,
								settlement_type_text: cargo_info.settlement_type_text,
								is_need_return_text: cargo_info.is_need_return_text,

								shipper_tel: item.shipper_info.tel,
								shipper_name: item.shipper_info.name,
								shipper_company_name: item.shipper_company_info.name,
								truck_plate_num: cargo_info.truck_plate_num,

								cargo_weight_start: cargo_info.cargo_weight,

								// cargo_weight_arrived: item.cargo_weight_arrived,
								// file_num: item.file_num,
								cargo_volume: cargo_info.cargo_volume,
								// service_total: item.service_total,
								// invoice_total: item.invoice_total,
								// calc_mode_text: item.calc_mode_text,
								// freight_allocation_mode_text: item.freight_allocation_mode_text,
								// payed_total_of_all: item.payed_total_of_all,
								// invoiced_total: item.invoiced_total,
								// invoiced_total_of_out_sc: item.invoiced_total_of_out_sc,
								cname: cargo_info.mark,
								// invoice_mark: item.invoice_mark,
								// system_profit: item.system_profit,
								// insure_total: item.cost_info.insure_total,
								goods_total:cargo_info.goods_total,
							})
						}

						//取出最后一个id
						if(data.list[data.list.length-1]){
                           this.id_max=data.list[data.list.length-1].id
						}

						//置入缓存
						this.buf=this.buf.concat(data.list)

						this.readed=this.buf.length
						
						//是否继续
						if(this.readed<this.max){//没有达到最大数量则继续读取

							this.read();
						
						}else{//达到最大数量则开始导出

							this.make_csv()
						}
					}
				})
			},

			//启动
			start(){

				//读取总数量
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_deal_list_admin',
						only_num:1,
						...this.rull,
					},
					callback:(data)=>{

						//如果总数量为0
						if(data.max==0){
							this.$my.other.msg({
								type:"warning",
								str:'该条件下数据为空'
							});
							return;
						
						//置入总数量
						}else this.max=data.max;

						//已读取数据
						this.buf=[];

						//开始读取
						this.read();
					}
				})
			},
		}
	}
</script>